import { DOMAIN_NAME } from "../settings/main";

const MAX_STARTPAGE_COOKIE_AGE = 300;
const STRATPAGE_COOKIE_BASE_OPTIONS = `domain=.${DOMAIN_NAME}; path=/; samesite=strict; secure=true`;
const STARTPAGE_COOKIE_NAME = (appId = '') => `__startpage[${appId}]`;

export function setAppStartPageCookie(appId = '') {
  const pathname = window.location.pathname;
  const search = window.location.search || ''
  if (!pathname || pathname === '/' || pathname === '/auth') { return }
  document.cookie = `${STARTPAGE_COOKIE_NAME(appId)}=${pathname + search}; ${STRATPAGE_COOKIE_BASE_OPTIONS}; max-age=${MAX_STARTPAGE_COOKIE_AGE};`;
}

function clearCookie(cookieName, baseOptions) {
  if (!cookieName) { return }
  document.cookie = `${cookieName}=; ${baseOptions}; max-age=0;`
}

export function getAppStartPageFromCookie(appId = '') {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${STARTPAGE_COOKIE_NAME(appId)}=`);
  if (parts.length !== 2) return
  const [path, search] = parts[1].split('?')
  const q = {}
  if (search) {
    for (const [key, value] of new URLSearchParams(search).entries()) { q[key] = value }
  }
  clearCookie(STARTPAGE_COOKIE_NAME(appId), STRATPAGE_COOKIE_BASE_OPTIONS)
  return [path, q]
}