import { DOMAIN_NAME } from "./main"

const AUTH_SUB_DOMAIN_NAME = 'accounts'

export function getAuthSubdomainURL() {
  return `https://${AUTH_SUB_DOMAIN_NAME}.${DOMAIN_NAME}`
}

export function getAuthURL(appId = '') {
  return `https://${AUTH_SUB_DOMAIN_NAME}.${DOMAIN_NAME}/auth/status?appId=${appId}`
}

export function getLogoutURL(appId = '') {
  return `https://${AUTH_SUB_DOMAIN_NAME}.${DOMAIN_NAME}/auth/signout?appId=${appId}`
}